<script setup lang="ts">
import { Icon } from 'webcc-ui-components'
import type { BreadcrumbItem } from '~/constants'

const props = defineProps<{
  items: BreadcrumbItem[]
}>()

const label = computed(() => props.items[props.items.length - 1].label)

function handleClick() {
  /**
   * If we have a history in current website - we go back in history
   * Otherwise we go to the previous page in breadcrumbs structure
   */
  if (
    Boolean(useRouter().options.history.state.back) &&
    window.history.length > 2
  ) {
    useRouter().go(-1)
  } else {
    const prevPageInStructure = props.items[props.items.length - 2]
    useRouter().replace(prevPageInStructure.url!)
  }
}
</script>

<template>
  <div
    data-id="breadcrumbs-mobile"
    class="cursor-pointer border-b border-txt-700 py-3 pl-5 shadow-none"
    @click="handleClick"
  >
    <div class="flex items-center gap-5">
      <Icon class="text-txt-400" size="md" graphic="arrow-left" />

      <p class="text-base font-medium leading-5">
        {{ label }}
      </p>
    </div>
  </div>
</template>
